/**
 * @prettier
 * @flow
 */
import {css, keyframes} from '@emotion/core';
import styled from '@emotion/styled';
import {between, rem} from 'polished';
import {responsiveSpacing} from '../../globals/functions';
import {
    breakpoints,
    colors,
    fontFamilies,
    fontSizes,
    spacing,
    transitions
} from '../../globals/variables';

export const greyText = css`
    color: ${colors.grey};
`;

export const centerText = css`
    text-align: center;
`;

export const uppercaseText = css`
    text-transform: uppercase;
`;

export const marginBottomS = css`
    margin-bottom: ${rem(spacing.s)};
`;

export const flexAtMobile = css`
    @media (min-width: ${rem(breakpoints.mobile)}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const textAlignRight = css`
    text-align: right;
`;

export const baseButton = css`
    background-color: transparent;
    display: inline-block !important;
    padding-left: 0;
    padding-right: 0;
`;

//

export const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;

    * {
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    * + * {
        margin-left: ${rem(spacing.m)};
    }
`;

//

const fadeInAnimation = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

export const FadeIn = styled.div`
    animation: ${fadeInAnimation} 0.6s;
    animation-delay: ${props => (props.delay ? `${props.delay / 1000}s` : 0)};
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;
    transition: ${transitions.bezier};
    will-change: opacity;
`;

const fadeInUpAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(${rem(spacing.s)});
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const FadeInUp = styled.div`
    animation: ${fadeInUpAnimation} 0.6s;
    animation-delay: ${props => (props.delay ? `${props.delay / 1000}s` : 0)};
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;
    transform: translateY(${rem(spacing.m)});
    transition: ${transitions.bezier};
    will-change: transform;
`;

//

const Heading = css`
    font-family: ${fontFamilies.heading};
    font-weight: 300;
    line-height: 1.15;
    margin-bottom: ${rem(spacing.m)};
    margin-top: 0;
`;

export const Heading1 = styled.h1`
    ${Heading};
    font-size: ${rem(fontSizes.h1 * 0.75)};

    @media (min-width: ${props => rem(breakpoints.tablet)}) {
        font-size: ${rem(fontSizes.h1)};
        font-size: ${between(`${fontSizes.h1}px`, `${fontSizes.h1 + 4}px`)};
    }
`;

export const Heading2 = styled.h2`
    ${Heading};
    font-size: ${rem(fontSizes.h2 * 0.75)};

    @media (min-width: ${props => rem(breakpoints.mobile)}) {
        font-size: ${rem(fontSizes.h2)};
    }

    @media (min-width: ${props => rem(breakpoints.desktop)}) {
        font-size: 6vmin;
    }
`;

export const Heading3 = styled.h3`
    ${Heading};
    font-size: ${rem(fontSizes.h3)};
`;

export const Heading4 = styled.h4`
    ${Heading};
    font-size: ${rem(fontSizes.h4)};

    ${props => props.primary && `color: ${colors.primary};`}
`;

export const Heading5 = styled.h5`
    ${Heading};
    font-family: ${fontFamilies.bold};
    font-size: ${rem(fontSizes.h5)};
    font-weight: 800;
`;

export const Heading6 = styled.h6`
    ${Heading};
    font-family: ${fontFamilies.bold};
    font-size: ${rem(fontSizes.h6)};
    font-weight: 800;
`;

//

export const Label = styled.span`
    font-size: ${rem(10)};
    font-weight: bold;
    letter-spacing: ${rem(0.1)};
    opacity: 0.9;
    text-transform: uppercase;
`;

//

export const LayoutContainer = styled.div`
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: ${rem(spacing.m * -1)};

    ${props =>
        props.type === 'matrix' &&
        `> div {
            margin-bottom: ${rem(spacing.l)} !important;
        }

        @media (min-width: ${rem(breakpoints.mobile)}) {
            > div {
                margin-bottom: 6vmax !important;
            }

            > div:nth-child(-n+3) {
                margin-bottom: 3vmax;
            }

            > div:nth-child(3n+2) {
                transform: translateY(4vmax);
            }

            > div:nth-child(3n+3) {
                transform: translateY(2vmax);
            }
        }
        `};

    ${props =>
        props.size &&
        `@media (min-width: ${rem(breakpoints.mobile)}) {
            margin-left: ${rem(spacing[props.size] * -1)};

            > div {
                padding-left: ${rem(spacing[props.size])};
            }
        }
        `};

    ${props =>
        props.stack &&
        `@media (max-width: ${rem(breakpoints.mobile)}) {
            > div + div {
                margin-top: ${rem(spacing.l)};
            }
        }
        `};
`;

const layoutWidth = size => (size * 100).toFixed(2);

export const LayoutItem = styled.div`
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    padding-left: ${rem(spacing.m)};
    width: ${props => (props.size ? `${layoutWidth(props.size)}%` : '100%')};

    ${props =>
        props.sizeAtMobileSmall &&
        `@media (min-width: ${rem(breakpoints.mobileSmall)}) {
        width: ${layoutWidth(props.sizeAtMobileSmall)}%;
    }`}

    ${props =>
        props.sizeAtMobile &&
        `@media (min-width: ${rem(breakpoints.mobile)}) {
        width: ${layoutWidth(props.sizeAtMobile)}%;
    }`}

    ${props =>
        props.sizeAtTablet &&
        `@media (min-width: ${rem(breakpoints.tablet)}) {
        width: ${layoutWidth(props.sizeAtTablet)}%;
    }`}

    ${props =>
        props.sizeAtDesktop &&
        `@media (min-width: ${rem(breakpoints.desktop)}) {
        width: ${layoutWidth(props.sizeAtDesktop)}%;
    }`}

    ${props =>
        props.isBlank &&
        `@media (max-width: ${rem(breakpoints.tablet)}) {
        display: none;
    }`}
`;

//

export const rteContent = props => css`
    > p:first-of-type {
        font-size: ${rem(fontSizes.lead)};
        letter-spacing: ${rem(0.5)};
        line-height: 1.5;

        ${props.hideFirstParagraph && 'display: none;'}
    }
`;

//

export const Spacer = styled.div`
    height: ${props => rem(spacing[props.size ? props.size : 'm'])};
    width: ${props => rem(spacing[props.size ? props.size : 'm'])};
`;

export const ResponsiveSpacer = styled.div`
    height: ${props => responsiveSpacing(spacing[props.size ? props.size : 'm'])};
    width: ${props => responsiveSpacing(spacing[props.size ? props.size : 'm'])};
`;

//

export const StickyAtMobile = styled.div`
    @media (min-width: ${rem(breakpoints.mobile)}) {
        position: sticky;
        top: ${rem(spacing.m)};
    }
`;

//

export const HideAt = styled.div`
    @media (min-width: ${props => rem(breakpoints[props.breakpoint])}) {
        display: none;
    }
`;

export const ShowAt = styled.div`
    @media (max-width: ${props => rem(breakpoints[props.breakpoint])}) {
        display: none;
    }
`;

export const VisuallyHidden = styled.div`
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
`;

//

export const Columns = styled.div`
    @media (min-width: ${rem(breakpoints.mobile)}) {
        columns: ${props => (props.columns ? props.columns : 2)};
        column-gap: ${rem(spacing.xl)};

        * {
            break-inside: avoid;
        }
    }
`;
